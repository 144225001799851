import styled from "styled-components";
import { device } from "../../layout/global-styles/device";

interface DashProps {
    readonly currentSlide?: number;
    readonly active?: boolean;
}

export const DashRow = styled.div`
    display: flex;
    padding: 1rem 0.5rem;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media ${device.tablet} {
        padding: 2rem 0;
    }
`;
export const DashRowBelow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    justify-content: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @media ${device.tablet} {
        padding: 2rem 0;
    }
`;

export const Dash = styled.button<DashProps>`
    width: 80px;
    height: ${(props) => (props.active ? "3px" : "1px")};
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;
    position: relative;
    border: none;
    box-shadow: none;

    background: ${(props) => props.theme.white};

    @media ${device.tablet} {
        width: 150px;
    }
`;
export const DashDark = styled.button<DashProps>`
    min-width: 20px;
    max-width: 80px;
    height: ${(props) => (props.active ? "3px" : "1px")};
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;
    position: relative;
    border: none;
    box-shadow: none;

    background: ${(props) => props.theme.black};

    @media ${device.tablet} {
        width: 150px;
    }
`;
